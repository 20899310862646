// in src/App.js
import React from "react";
import jsonServerProvider from "ra-data-json-server";
import NotFound from "./NotFound";
import addUploadFeature from "./addUploadFeature";
import { createBrowserHistory as createHistory } from 'history';
import { Admin, Resource, fetchUtils } from "react-admin";

import {
  AdminUserList,
  AdminUserEdit,
  AdminUserCreate,
  AdminUserShow
} from "./admin_users/index";
import {
  AdminUserGroupsList,
  AdminUserGroupsEdit,
  AdminUserGroupsCreate,
  AdminUserGroupsShow
} from "./admin_users_groups/index";
import {
  TestimonialsList,
  TestimonialsEdit,
  TestimonialsCreate
} from "./testimonials/index";
/*import { SettingsList, SettingsEdit } from "./settings/index";*/
import { ConfigemailsEdit, ConfigemailsList, ConfigemailsShow } from "./configemails/index";
import { UserList, UserCreate, UserShow } from "./users/index";
import {
  AddressesList,
  AddressesCreate,
  AddressesEdit,
  AddressesShow
} from "./addresses/index";
import { RolesList, RolesShow, RolesEdit, RolesCreate } from "./roles/index";
import { MetasList, MetasShow, MetasEdit, MetasCreate } from "./metas/index";
import { CreditsCreate, CreditsEdit, CreditsShow } from "./credits/index";
import {
  UserGroupList,
  UserGroupEdit,
  UserGroupCreate,
  UserGroupShow
} from "./usersgroups/index";
import { OrdersList } from "./orders/index";
import LoginPage from './LoginPage';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import BorderColorIcon from '@material-ui/icons/BorderColor';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import BlurLinearIcon from '@material-ui/icons/BlurLinear';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SettingsIcon from "@material-ui/icons/Settings";
import ClearAllIcon from '@material-ui/icons/ClearAll';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import ListAltIcon from "@material-ui/icons/ListAlt";
import PeopleIcon from "@material-ui/icons/People";
import PersonIcon from '@material-ui/icons/Person';
import AppsIcon from '@material-ui/icons/Apps';
import DnsIcon from '@material-ui/icons/Dns';

import {
  BannersList,
  BannersEdit,
  BannersCreate,
  BannersShow
} from "./banners/index";
import {
    ProductsList,
    ProductsShow,
    ProductsEdit,
    ProductsCreate
} from "./products/index";
import {
    CategoriesList,
    CategoriesEdit,
    CategoriesCreate,
    CategoriesShow
} from "./categories/index";
import { HomeProductsList } from "./homeproducts/index";
import { CategoryProductsList } from "./productcategories/index";
import { GalleriesEdit } from "./galleries/index";
import { CategoryGalleriesEdit } from "./categorygalleries/index";
import authProvider from "./authProvider";
import customRoutes from "./customRoutes";
import { createMuiTheme } from "@material-ui/core/styles";
import "./css/main.css";
const history = createHistory();
const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#B4B000",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
      contrastText: "#fff"
    },

    secondary: {
      light: "#0066ff",
      main: "#B4B000",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#fff"
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2
  }
});
const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("token");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider(
  process.env.REACT_APP_API_URL,
  httpClient
);
const uploadCapableDataProvider = addUploadFeature(dataProvider);

const fetchResources = permissions =>{
  const accessControl = !permissions ? []:JSON.parse(permissions) ;
  return [
    <Resource
      name="adminusers"
      icon={SupervisorAccountIcon}
      filters={{ deleted: 0 }}
      label="Admin Users"
      options={{ label: "Admin Users" }}
      show={
        accessControl["adminusers"] &&
        accessControl["adminusers"].includes("show")
          ? AdminUserShow
          : null
      }
      list={
        accessControl["adminusers"] &&
        accessControl["adminusers"].includes("list")
          ? AdminUserList
          : null
      }
      edit={
        accessControl["adminusers"] &&
        accessControl["adminusers"].includes("edit")
          ? AdminUserEdit
          : null
      }
      create={
        accessControl["adminusers"] &&
        accessControl["adminusers"].includes("create")
          ? AdminUserCreate
          : null
      }
    />,
    <Resource
      name="admingroups"
      icon={PeopleAltIcon}
      options={{ label: "Admin User Groups" }}
      show={
        accessControl["admingroups"] &&
        accessControl["admingroups"].includes("show")
          ? AdminUserGroupsShow
          : null
      }
      list={
        accessControl["admingroups"] &&
        accessControl["admingroups"].includes("list")
          ? AdminUserGroupsList
          : null
      }
      edit={
        accessControl["admingroups"] &&
        accessControl["admingroups"].includes("edit")
          ? AdminUserGroupsEdit
          : null
      }
      create={
        accessControl["admingroups"] &&
        accessControl["admingroups"].includes("create")
          ? AdminUserGroupsCreate
          : null
      }
    />,
    <Resource
      name="orders"
      icon={ListAltIcon}
      list={
        accessControl["orders"] && accessControl["orders"].includes("list")
          ? OrdersList
          : null
      }
      // className="btn-link"
    />,
    <Resource
      name="products"
      icon={AppsIcon}
      show={
        accessControl["products"] && accessControl["products"].includes("show")
          ? ProductsShow
          : null
      }
      list={
        accessControl["products"] && accessControl["products"].includes("list")
          ? ProductsList
          : null
      }
      edit={
        accessControl["products"] && accessControl["products"].includes("edit")
          ? ProductsEdit
          : null
      }
      create={
        accessControl["products"] &&
        accessControl["products"].includes("create")
          ? ProductsCreate
          : null
      }
    />,
      <Resource
          name="categories"
          icon={DnsIcon}
          show={
              accessControl["categories"] && accessControl["categories"].includes("show")
                  ? CategoriesShow
                  : null
          }
          list={
              accessControl["categories"] && accessControl["categories"].includes("list")
                  ? CategoriesList
                  : null
          }
          edit={
              accessControl["categories"] && accessControl["categories"].includes("edit")
                  ? CategoriesEdit
                  : null
          }
          create={
              accessControl["categories"] && accessControl["categories"].includes("create")
                  ? CategoriesCreate
                  : null
          }
      />,
      <Resource
          name="productcategories"
          icon={BlurLinearIcon}
          options={{ label: "Product Categories" }}
          list={
              accessControl["productcategories"] &&
              accessControl["productcategories"].includes("list")
                  ? CategoryProductsList
                  : null
          }
      />,
    <Resource
      name="homepageproducts"
      icon={AttachFileIcon}
      options={{ label: "Featured Products" }}
      list={
        accessControl["homepageproducts"] &&
        accessControl["homepageproducts"].includes("list")
          ? HomeProductsList
          : null
      }
    />,
    <Resource
      name="banners"
      icon={ViewCarouselIcon}
      show={
        accessControl["banners"] && accessControl["banners"].includes("show")
          ? BannersShow
          : null
      }
      list={
        accessControl["banners"] && accessControl["banners"].includes("list")
          ? BannersList
          : null
      }
      edit={
        accessControl["banners"] && accessControl["banners"].includes("edit")
          ? BannersEdit
          : null
      }
      create={
        accessControl["banners"] && accessControl["banners"].includes("create")
          ? BannersCreate
          : null
      }
    />,
      <Resource
      name="testimonials"
      icon={ClearAllIcon}
      list={
        accessControl["testimonials"] &&
        accessControl["testimonials"].includes("list")
          ? TestimonialsList
          : null
      }
      edit={
        accessControl["testimonials"] &&
        accessControl["testimonials"].includes("edit")
          ? TestimonialsEdit
          : null
      }
      create={
        accessControl["testimonials"] &&
        accessControl["testimonials"].includes("create")
          ? TestimonialsCreate
          : null
      }
    />,
    <Resource
      name="users"
      icon={PersonIcon}
      show={
        accessControl["users"] && accessControl["users"].includes("show")
          ? UserShow
          : null
      }
      list={
        accessControl["users"] && accessControl["users"].includes("list")
          ? UserList
          : null
      }
      create={
        accessControl["users"] && accessControl["users"].includes("create")
          ? UserCreate
          : null
      }
    />,
    <Resource
      name="usersgroups"
      icon={PeopleOutlineIcon}
      options={{ label: "User Groups" }}
      show={
        accessControl["usersgroups"] &&
        accessControl["usersgroups"].includes("show")
          ? UserGroupShow
          : null
      }
      list={
        accessControl["usersgroups"] &&
        accessControl["usersgroups"].includes("list")
          ? UserGroupList
          : null
      }
      edit={
        accessControl["usersgroups"] &&
        accessControl["usersgroups"].includes("edit")
          ? UserGroupEdit
          : null
      }
      create={
        accessControl["usersgroups"] &&
        accessControl["usersgroups"].includes("create")
          ? UserGroupCreate
          : null
      }
    />,
    <Resource
      name="roles"
      icon={GroupAddIcon}
      label="Admin Users"
      options={{ label: "Member Permissions" }}
      list={
        accessControl["roles"] && accessControl["roles"].includes("list")
          ? RolesList
          : null
      }
      show={
        accessControl["roles"] && accessControl["roles"].includes("show")
          ? RolesShow
          : null
      }
      edit={
        accessControl["roles"] && accessControl["roles"].includes("edit")
          ? RolesEdit
          : null
      }
      create={
        accessControl["roles"] && accessControl["roles"].includes("create")
          ? RolesCreate
          : null
      }
    />,
   <Resource
      name="addresses"
      icon={ContactMailIcon}
      
      show={
        accessControl["addresses"] &&
        accessControl["addresses"].includes("show")
          ? AddressesShow
          : null
      }
      create={
        accessControl["addresses"] &&
        accessControl["addresses"].includes("create")
          ? AddressesCreate
          : null
      }
      edit={
        accessControl["addresses"] &&
        accessControl["addresses"].includes("edit")
          ? AddressesEdit
          : null
      }
    />,
    <Resource
      name="metas"
      icon={BorderColorIcon}
      show={MetasShow}
      list={
        accessControl["metas"] &&
        accessControl["metas"].includes("list")
          ? MetasList
          : null
      }
      edit={MetasEdit}
      create={MetasCreate}
    />,
    <Resource
      name="galleries"
      list={null}
      edit={
        accessControl["galleries"] &&
        accessControl["galleries"].includes("edit")
          ? GalleriesEdit
          : null
      }
    />,
      <Resource
      name="categorygalleries"
      list={null}
      edit={
        accessControl["categorygalleries"] &&
        accessControl["categorygalleries"].includes("edit")
          ? CategoryGalleriesEdit
          : null
      }
    />,
    /*<Resource
      name="settings"
      icon={SettingsIcon}
      list={
        accessControl["settings"] && accessControl["settings"].includes("list")
          ? SettingsList
          : null
      }
      edit={
        accessControl["settings"] && accessControl["settings"].includes("edit")
          ? SettingsEdit
          : null
      }
    />,*/
    <Resource
      name="configemails"
      icon={SettingsIcon}
      list={
        accessControl["configemails"] && accessControl["configemails"].includes("list")
          ? ConfigemailsList
          : null
      }
      edit={
        accessControl["configemails"] && accessControl["configemails"].includes("edit")
          ? ConfigemailsEdit
          : null
      }
      show={
          accessControl["configemails"] &&
          accessControl["configemails"].includes("show")
              ? ConfigemailsShow
              : null
      }
    />,
    <Resource
      name="credits"
      icon={PeopleIcon}
      show={
        accessControl["credits"] && accessControl["credits"].includes("show")
          ? CreditsShow
          : null
      }
      edit={
        accessControl["credits"] && accessControl["credits"].includes("edit")
          ? CreditsEdit
          : null
      }
      create={
        accessControl["credits"] && accessControl["credits"].includes("create")
          ? CreditsCreate
          : null
      }
    />,
    <Resource name="productstype" />,
    <Resource name="countries" />,
    <Resource name="adminpermissions" />,
    <Resource name="rolesPermissions" />
  ]

}
const App = (props) => {
  return (<Admin
    theme={theme}
    history={history}
    loginPage={LoginPage}
    locale="en"
    customRoutes={customRoutes}
    catchAll={NotFound}
    dataProvider={uploadCapableDataProvider}
    authProvider={authProvider}
  >
    {fetchResources}
    
  </Admin>)
}
export default App;
