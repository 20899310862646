import React from 'react';
import {
    List,
    Create,
    Show,
    SimpleShowLayout,
    Datagrid,
    Edit,
    SimpleForm,
    Filter,
    TextField,
    CardActions,
    ExportButton,
    Pagination,
    EditButton,
    DisabledInput,
    TextInput,
    DateField,
    SelectInput,
    BooleanField,
    Button
} from 'react-admin';
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import ShowButton from "admin-on-rest/lib/mui/button/ShowButton";

let devPermission = JSON.parse(localStorage.getItem('devPermission'))
let showOrderCreateBtn = false
if (devPermission !== null && devPermission.showOrderCreateBtn === true) {
    showOrderCreateBtn = devPermission.showOrderCreateBtn;
}

const OrdersFilter = (props) => (
    <Filter {...props}>
        <TextInput label="id" source="id" alwaysOn/>
        {/*<TextInput label="Order Id" source="orderId" alwaysOn />*/}
        <TextInput label="User" source="email" alwaysOn/>
        {/*<TextInput label="Support Admin" source="supportAdmin" alwaysOn />*/}
        <TextInput label="Product" source="productName" alwaysOn/>
        <SelectInput source="statusId" label="Status" alwaysOn choices={[
            {id: '1', name: 'Project Received'},
            {id: '2', name: 'Details Incorrect'},
            {id: '8', name: 'Project Details Verified'},
            {id: '19', name: 'Prepress'},
            {id: '3', name: 'Waiting for Approval'},
            {id: '9', name: 'Project Approved'},
            {id: '11', name: 'Request Modification'},
            {id: '6', name: 'Payment Pending'},
            {id: '17', name: 'In Production'},
            {id: '20', name: 'Ready For Shipment'},
            {id: '18', name: 'Out For Delivery'},
            {id: '5', name: 'Project Completed'},
            {id: '4', name: 'Project Canceled'},
        ]}/>
    </Filter>
);
const EmailUrlBtn = ({record}) => {
    if (!record || !record.user || !record.user.id) return null;
    const url = `/users/${record.user.id}/show`;
    return (
        <a href={url} color="primary" target="_blank"> {record.user.email}</a>
    )
}
const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;


const PostActions = ({
                         bulkActions,
                         basePath,
                         currentSort,
                         displayedFilters,
                         exporter,
                         filters,
                         filterValues,
                         onUnselectItems,
                         resource,
                         selectedIds,
                         showFilter,
                         total
                     }) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}

        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
        />

        {showOrderCreateBtn ? (
            <a href="/orders/createOrder/" className="custom-create-btn">

                <span className="MuiButton-label-220"><svg
                    className="MuiSvgIcon-root-184 translate-Button--smallIcon-94" focusable="false" viewBox="0 0 24 24"
                    aria-hidden="true"><g><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path></g></svg><span
                    className="translate-Button--label-92">Create</span></span>
                <span className="MuiTouchRipple-root-76"></span>
            </a>
        ) : ''}

    </CardActions>
);
const PriceField = props => {
    return <TextField className={`status-key-${props.record.orderStatuses.key}`} {...props} />;
}

const EditButtonHandler =(props,{record}) => {
    console.log("clicked")
    return props.record.createdUser.deleted ? <EditButton label="Show" basePath={props.basePath} {...props} icon={""}/> : <EditButton basePath={props.basePath} className="custom-create-btn" {...props} />
}

export const OrdersList = (props) => (

    <>
        <List filters={<OrdersFilter/>} actions={<PostActions/>} bulkActions={false}  {...props}
              pagination={<PostPagination/>}>

            <Datagrid>
                <TextField source="orderProduct.product.name" sortable={false} label="Product"/>
                <TextField source="id" label="WebCenter Id"/>
                <TextField source="user.accountInfo.companyName" label="Company Name"/>
                <TextField source="user.firstname" label="First Name"/>
                <EmailUrlBtn label="Email"/>
                <PriceField source="orderStatuses.name" sortBy='statusId' label="Status" />
                <BooleanField  source="createdUser.deleted"  label="Deleted" />
                {/*<TextField  source="orderStatuses.name" sortBy='statusId' label="Status"/>*/}
                <DateField  source="createdAt" label="Created in" showTime/>
                <EditButtonHandler basePath="/orders/edit" />
                {/* <ShowButton basePath="/orders" /> */}
            </Datagrid>
        </List>
    </>
);


export const OrdersEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <DisabledInput source="id"/>
            <DisabledInput source="orderId"/>
        </SimpleForm>
    </Edit>
);

export const OrdersCreate = (props) => (
    <Create title="Create Order" {...props}>
        <SimpleForm>
            <DisabledInput source="id"/>
        </SimpleForm>
    </Create>
);

export const OrdersShow = (props) => (
    <Show reference="orders" {...props} >
        <SimpleShowLayout>
            <TextField source="id"/>
        </SimpleShowLayout>
    </Show>
);
