import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import {getTypes,getValidations,getCalculationLogics,getOptions,deleteOption,getAttributes} from "../services/options"
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import OptionItem, {Tabs} from "./OptionItem";
import axios from "axios";
import authHeader from "../_helpers/authHeader";
import _  from "lodash";
class ProductOptions extends Component {
    constructor(props){
        super(props);
        this.state={
            addEmpty:false,
            options: [],
            types:[],
            validations: [],
            calculationLogics: [],
            attributes:[]
        }
        this.options= [];
        this.addEmptyOption  = this.addEmptyOption.bind(this);
        this.deleteOption  = this.deleteOption.bind(this);
        this.changeValue  = this.changeValue.bind(this);
        this.saveOptions  = this.saveOptions.bind(this);
        this.setValidations = this.setValidations.bind(this);
        this.addRows = this.addRows.bind(this)
    }
    addEmptyOption(){
        const { options } = this.state;
        options.unshift({
            new:true,
            optionId:`option_${options.length}`,
            label:'',
            sortOrder:0,
            validations:[],
            defaultValue:"",
            calculateLogicId:1,
            price:0,
            units:"number",
            fields:[],
            typeId:1});
       
        this.setState(options)
    }
    changeValue(option){
        const { options } = this.state;
        const index = options.findIndex(x=> x.optionId === option.optionId);
        options[index] = option; 
        this.options = options;
        this.setState({options});
    }
    setValidations(option){
        const { options } = this.state;
        const index = options.findIndex(x=> x.optionId === option.optionId);
        options[index] = option;
        console.log( "options in validations ", options[index])
        this.options = options;
        this.setState({options});
    }

    addRows(option){
        const { options } = this.state;
        const index = options.findIndex(x=> x.optionId === option.optionId);
        options[index] = option; 
        this.options = options;
        this.setState({options});
    }
    deleteOption(id){
        const { options } = this.state;
        const index = options.findIndex(x=> x.optionId === id);
        if(index !== -1){
            const currentComponent  = this;
            if(options[index].id){
                deleteOption(options[index].id)
                    .then(deleted=>{
                        options.splice(index,1); 
                        currentComponent.setState(options)
                    })
                    .catch(error=>{
                        console.log('erro deleted',error)
                    })
            }else{
                options.splice(index,1); 
                this.setState(options)
            }
        }
        
    }
    componentWillMount(){
        const component = this;

        getTypes().then(data=>{
            if(data.data){
                component.setState({types:data.data});
            }
        });
        getValidations().then(data=>{
            component.setState({validations:data.data});
        });
        getCalculationLogics().then(data=>{
            component.setState({calculationLogics:data.data});
        });
        getOptions(this.props.match.params.id).then(data=>{
            component.setState({options:data.data});
        }).catch(error=>{
            console.log(error)
        })
        getAttributes().then(data=>{
            component.setState({attributes:data.data.attributes});
        })
        .catch(error=>{
            console.log(error)
        })
    }
    saveOptions(){
        const { options } = this.state;
        const component = this;
        if(!options || options.length === 0) return false;
        const formData = new FormData();
        for (const option of options) {
            if(option.fields && option.fields.length > 0){
                for (const row of option.fields) {
                    if(row.image){
                        formData.append(`${option.optionId}_${row.fieldId}`,row.image);
                    }
                }
            }
        }

        // test
        let firstPart = Math.floor(options.length/5);

        // let optionsArr = Object.entries(options);
        let optionsArrChunk = _.chunk( options, firstPart);



        const req = optionsArrChunk.reduce((acc, itm, idx) => {
            acc[`options_${idx}`] = itm;




            formData.append(`options_${idx}`,JSON.stringify(itm));
            return acc;
        }, {})

        console.log( req );
        // return;
        axios.post(`${process.env.REACT_APP_API_URL}/productoptions/${this.props.match.params.id}`,formData,
        {
            headers:{
                Authorization:authHeader().Authorization,
            }
          }).then((response) =>{
              if(response.status === 200 && response.data){
                window.location.reload()
              }
      });
    }
    render(){
        const {options,calculationLogics,types,validations,attributes} = this.state;
        
        return (
            <div>
                <Button onClick={this.addEmptyOption} color="primary" className="custom-create-btn btn-text mb-2">  <PlaylistAddIcon /> Add Option</Button>
                <Button color="primary" onClick={this.saveOptions} variant="contained" className='float-right mb-2'>  <SaveAltIcon /> Save</Button>
                {options.length >0  ? 
                    options.map((option)=>{
                        const key = option.optionId ? option.optionId : option.id;
                        return <OptionItem key={key} 
                            optionId={option.optionId}
                            options={options}
                            calculationLogics={calculationLogics} 
                            types={types} 
                            attributes={attributes}
                            validations={validations} 
                            option={option} 
                            addRows={this.addRows} 
                            setValidation={this.setValidations} 
                            changeValue={this.changeValue} 
                            deleteOption={this.deleteOption}/>
                    })        
                    :
                    null
                }
            </div>
        )
    }
}

export default ProductOptions